// Grid
.grid-contact {
  color: $color-purple;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 70px 90px;
  margin: 50px 120px 120px 120px;

  h3 {color: $color-purple; margin-bottom: 12px;}
  a {color: $color-purple; font-size: 1.15em}
  p {margin-bottom: 20px;}
  label, .indicates-required {@include Gilroy-Bold; color: $color-purple;}

  input {
    @include Gilroy-Regular;
    font-size: .8em;
    width: 100%;
    height: 40px;
    margin: 6px 0 16px 0;
    padding-left: 10px;
    border: 1px solid $color-purple;
    &:focus {outline: none;}
  }

  li {margin-bottom: 6px;}

  .button {
    @include Gilroy-Bold;
    font-size: 1.05em;
    width: 200px;
    background-color: $color-purple;
    color: $color-white;
    cursor: pointer;

    &:hover {
      background-color: $color-red;
      border-color: $color-red;
    }
    &:focus {outline: none;}
  }
  @media (max-width: 1400px) {margin: 40px 108px 100px 108px;}

  @media (max-width: 1080px) {
    grid-gap: 50px 40px;
    margin: 30px 84px 80px 84px;
  }

  @media (max-width: 870px) {
    grid-template-columns: repeat(1, 1fr);
    margin: 50px 90px 90px 90px;
    h3 {margin-bottom: 8px;}
  }

  @media (max-width: 700px) {
    grid-gap: 40px 0;
    margin: 32px 36px 50px 36px;
    h3 {margin-bottom: 4px; font-size: 1.05em;}
    label {font-size: .9em;}
    a {font-size: 1.05em;}
    .button {width: 100%;}
  }
  @media (max-width: 480px) {grid-gap: 30px 0; margin-top: 24px;}
}

.page-title-contact {@media (max-width: 700px) {margin-left: 36px;}}

#mc_embed_signup, .contact-info {
  padding-bottom: 30px;
  border-bottom: 1px solid $color-purple;
}
