
#myVideo {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-color: black;
    background-position: center center;
    background-size: contain;
    object-fit: cover;
    //z-index: 1;
    opacity: .6;
    mix-blend-mode: multiply;
}

#section0 {
    overflow: hidden;
}
