@import "components/nav-responsive";
@import "components/nav-scroll";
@import "components/text-animation";

header {
	display: flex;
  justify-content: space-between;
	width: 100vw;
	min-height: 20px;
	height: 100px;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	padding: 40px 50px 30px 40px;

	@media (max-width: 1200px) {padding: 40px 30px;}
	@media (max-width: 1080px) {padding: 30px 30px;}
  @media (max-width: 700px) {padding: 0;}
}

.brand-logo {
	z-index: 999;
	position: relative;
  width: 220px;
  @media (max-width: 1080px) {width: 190px;}
	@media (max-width: 870px) {width: 170px;}
  @media (max-width: 700px) {width: 140px; margin: 20px 20px;}
}

.logoNav {fill: $color-purple;}
.logoArticle {fill: $color-white;}

.fill {.logoNav {fill: $color-white !important;}}

.navbar {
	top: 30px;
	@media (max-width: 700px) {display: none;}

  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    li {
      padding: 10px 14px;
      margin-left: 4px;
      color: $color-white;
      text-align: center;

      @media (max-width: 870px) {padding: 10px 9px;}
    }
		a {
			font-size: 1.2em;
			@media (max-width: 1080px) {font-size: 1.05em;}
		}
  }
}
