.social-feed {
  //top: 50px;
  margin: 140px 80px 60px 80px;
  z-index: 0;
  text-align: left;

  @media (max-width: 870px) {margin: 120px 80px 60px 80px;}
  @media (max-width: 700px) {margin: 90px 24px 30px 24px;}
}

.crt-post .crt-post-header {
  padding-top: 14px !important;
}

.crt-post, .crt-post-header, .crt-post-twitter, .crt-post-has-text,  .crt-post-content-image-hidden, .crt-post-no-image {
  color: $color-purple !important;
}

.crt-post-fullname, .crt-post-username {
  font-size: .75em !important;
  opacity: 1 !important;
  color: $color-purple !important;
}

.crt-post-username, .crt-post-date, {
  a {
    font-size: .75em !important;
    line-height: 1.4em;
  }
}

.crt-post-share {
  a {
    font-size: .8em !important;
    width: auto;
  }
}

.crt-post {
  line-height: 1.8em !important;
}

.crt-post-text {
  font-size: .72em !important;
  line-height: 1.6em !important;
  @include Gilroy-Bold;

  a {
    font-size: .95em !important;
    line-height: 1.6em;
  }
}

.crt-image {
  cursor: pointer;
}

.crt-popup-header {
  img {
    float: left;
    width: 36px !important;
    margin-right: 9px;
    margin-top: 7px;
  }
}

.crt-post-name {
  @include Gilroy-Bold;
  color: $color-purple !important;
  left: 95px !important;
  a {
    font-size: .9em !important;
  }
}

.crt-social-icon {
  padding-top: 8px !important;
  margin-bottom: 6px !important;
}


.crt-popup-text-body {
  @include Gilroy-Regular;
  font-size: .95em !important;
  line-height: 1.5em;
  color: $color-grey !important;
  a {
    font-size: .95em !important;
    color: $color-grey !important;
  }
}

.crt-social-icon i {
  color: $color-purple !important;
}

.crt-popup-read-more {
  font-size: .7em !important;
}

.crt-date {
  font-size: .7em !important;
  margin-bottom: 12px;
}

.crt-close, .crt-icon-cancel {
  font-size: .95em !important;
}

.crt-load-more {
  @include Gilroy-Bold;
  font-size: .95em !important;
  color: $color-purple !important;

  @media (max-width: 700px) {margin-bottom: 30px !important;}
}

.crt-widget.crt-widget-branded .crt-logo {
  width: auto;
}

.curator {
  color: #999999;
  @include Gilroy-Regular;
  font-size: 1em;
}

.crt-close {
  right: -100px !important;
}

.crt-popup-read-more a.crt-button {
  border-radius: 0px !important;
  //background-color: #efefef;
  //color: #2e2e2e;
  width: auto !important;
}
