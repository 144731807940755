.mc-layout__modalContent {
  box-shadow: none !important;
  display: block;
  background: #FFFFFF;
  border-radius: 0px !important;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch;
}
