.news-post, .news-post-red {
  position: relative;
  //height: 860px;

  @media (max-width: 1400px) {height: 700px;}
  @media (max-width: 1080px) {height: 560px;}
  @media (max-width: 870px) {height: 440px;}
  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    height: 500px;
  }
}

.news-post {background-color: $color-purple;}
.news-post-red {background-color: $color-red;}

.news-post-img {
  display: block;
  float: right;
  width: 50%;
  height: 100%;
  object-fit: cover;

  @media (max-width: 640px) {float: left; width: 100%; height: 50%;}
}

.news-post-content {
  position: relative;
  margin: 0;
  float: left;
  width: 50%;
  height: 100%;
  padding: 150px 100px 100px 60px;

  @media (max-width: 1400px) {padding: 130px 80px 80px 60px;}
  @media (max-width: 1080px) {padding: 90px 60px 60px 54px;}
  @media (max-width: 870px) {padding: 70px 50px 50px 46px;}
  @media (max-width: 640px) {width: 100%; padding: 30px 30px 40px 20px;}

  h1 {
    position: absolute;
    max-width: 700px;
    margin-right: 110px;
    @media (max-width: 870px) {margin-right: 70px;}
    @media (max-width: 870px) {font-size: 1.25em;}
  }

  h3 {
    position: relative;
    text-align: left;
    margin-bottom: 120px;
    color: $color-red2;
    text-transform: uppercase;
    letter-spacing: .4em;

    &:after {
      position: absolute;
      content: "";
      left: 0;
      width: 50px;
      top: 40px;
      height: 2px;
      background-color: $color-red;

      @media (max-width: 1080px) {width: 40px; top: 32px;}
    }

    @media (max-width: 1400px) {margin-bottom: 100px;}
    @media (max-width: 1080px) {margin-bottom: 78px;}
    @media (max-width: 870px) {margin-bottom: 66px;}
    @media (max-width: 640px) {margin-bottom: 50px;}
  }
}
