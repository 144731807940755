.page-title {
  margin-top: 160px;
  margin-left: 117px;
  color: $color-purple;

  @media (max-width: 1400px) {
    margin-left: 106px;
  }

  @media (max-width: 1080px) {
    margin-top: 140px;
    margin-left: 67px;
  }

  @media (max-width: 700px) {
    margin-top: 110px;
    margin-left: 38px;
  }

  @media (max-width: 480px) {
    margin-top: 90px;
    margin-left: 26px;
  }
}

.page-title-releases {
  @media (max-width: 1080px) {margin-left: 84px;}
  @media (max-width: 870px) {margin-left: 89px;}
  @media (max-width: 700px) {margin-left: 39px;}
}
