// Buttons

.button {
  display: block;
  background-color: 333;
  width: 200px;
  height: 40px;
  border: 1px solid $color-white;
  padding: 7px;

  &:hover {
    background-color: white;
    color: $color-purple;
  }

  @media (max-width: 700px) {
    padding: 9px;
  }

  @media (max-width: 480px) {
    width: 250px;
  }
}
