@import "components/page-title";

// Grid
.grid-artists {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px 20px;
  margin: 30px 120px 120px 120px;

  @media (max-width: 1400px) {
    margin: 30px 108px 100px 108px;
  }

  @media (max-width: 1080px) {
    grid-gap: 40px 16px;
    margin: 22px 70px 80px 70px;
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    margin: 18px 40px 50px 40px;
  }

  @media (max-width: 480px) {
    margin: 14px 28px 46px 28px;
  }
}

.artist-card {
  position: relative;
  border-bottom: 1px solid $color-purple;
  h3, h4, h5, a {color: $color-purple;}
  h3 {
    font-size: 1.375em;
    margin-top: 18px;
    margin-bottom: 4px;

    @media (max-width: 1080px) {font-size: 1.18em;}
    @media (max-width: 480px) {font-size: 1.1em; margin-top: 14px; margin-bottom: 0px;}
  }
  h4 {
    @include Gilroy-Regular;
    margin-top: 4px;
    margin-bottom: 14px;
    @media (max-width: 1080px) {font-size: .9em; margin-bottom: 12px;}
    @media (max-width: 480px) {font-size: .85em; margin-top: 1px;}
  }
  h5  {
    @include Gilroy-Regular;
    margin-bottom: 14px;
  }
  a {
    position: absolute;
    font-size: 1em;
    float: right;
    right: 0;
    bottom: 0;
  }
}

.release-text {
  position: relative;
}

.artists {
  overflow: hidden;
  position: relative;

  .plus-icon-top, .plus-icon-bottom {
    position: absolute;
    background-color: $color-white;
    transition: all .2s ease-in-out;
  }

  a {
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    border: 0;
    opacity: 0;
  }

  img {
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    object-fit: cover;
  }

  @media (min-width: 870px) {
    .plus-icon-top {top: 32px; right: 20px; width: 0px; height: 1px;}
    .plus-icon-bottom {top: 20px; right: 32px; width: 1px; height: 0px;}
    &:hover {
      .plus-icon-top {width: 25px;}
      .plus-icon-bottom {height: 25px;}
    }
    a {
      transition: all .3s ease-in-out;
      &:hover {
        background-color: $color-red;
        opacity: 0.7;
        .plus-icon-top {width: 25px;}
      }
    }
  }
}
