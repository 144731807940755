.grid-videos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px 20px;
  margin: 0px 0px 70px 0px;

  @media (max-width: 1080px) {
    grid-gap: 40px 16px;
    margin: 0px 0px 70px 0px;
  }

  @media (max-width: 870px) {
    margin: 0px 0px 30px 0px;
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    margin: 0px 0px 40px 0px;
  }

  @media (max-width: 480px) {grid-gap: 32px 16px; margin: 0px 0px 20px 0px;}
}



.video-card {
  position: relative;
  border-bottom: 1px solid $color-purple;
  h4, h5, a {color: $color-purple;}

  h4 {
    margin-top: 18px;
    margin-bottom: 4px;
  }
  h5  {
    @include Gilroy-Regular;
    margin-bottom: 14px;
  }

  img {
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    object-fit: cover;
  }

  a, .thumbnail-fill, .play-btn {
    position: absolute;
    top: 0;
    left: 0;
  }

  .play-btn {
    width: 70px;
    height: 70px;
    margin: auto;
    right: 0;
    bottom: 0;

    @media (max-width: 870px) {
      width: 50px;
      height: 50px;
    }
  }

  a, .thumbnail-fill {
    width: 100%;
    height: 100%;
    border: 0;
  }

  .thumbnail-fill {
    opacity: 0;
    background-color: $color-red;
    transition: all .25s ease-in-out;
  }

  @media (min-width: 870px) {
    &:hover {
      .thumbnail-fill {opacity: 0.7;}
    }
  }

  @media (max-width: 870px) {
    h4 {margin-top: 16px; margin-bottom: 2px;}
    h5  {margin-bottom: 12px;}
  }

  @media (max-width: 480px) {
    h4 {margin-top: 12px; margin-bottom: 0px; font-size: .86em;}
    h5  {margin-bottom: 8px; font-size: 0.8em;}
  }
}

.video-thumb {
  position: relative;
}
