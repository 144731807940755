footer {
	position: absolute;
	left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 4;
  width: 100%;
  padding: 40px 50px;
	clear: both;

  ul {
    margin: 0;
    padding: 0;
    display: flex;

    li {margin-left: 6px; padding: 0 10px;}

    a {font-size: 1em; text-align: center;}
  }

  @media (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 40px 10px;

    ul {
      justify-content: center;
      align-items: center;

      li {margin: 0; text-align: center;}
    }

    h4 {margin-bottom: 16px; text-align: center;}
  }

  @media (max-width: 700px) {
    padding: 20px 10px;
    h4 {margin-bottom: 8px; text-align: center;}
    }

  @media (max-width: 480px) {
		h4 {font-size: .8em;}
    ul {
      li {margin-left: 0; padding: 0 7px;}
      a {font-size: .74em}
    }
  }
}

#home-footer {
	background-color: $color-purple !important;
}

.fp-tableCell {
	display: flex;
	justify-content: space-between;

	@media (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;

  }
}
