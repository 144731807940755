@import "components/page-title";

// Grid
.grid-releases {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 50px 20px;
  margin: 30px 120px 120px 120px;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1400px) {
    margin: 22px 108px 100px 108px;
  }

  @media (max-width: 1080px) {
    grid-gap: 40px 16px;
    margin: 18px 84px 80px 84px;
  }

  @media (max-width: 870px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 18px 90px 90px 90px;
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    margin: 18px 40px 50px 40px;
  }

  @media (max-width: 480px) {
    margin-top: 14px;
  }
}

.grid-releases-profile {
  grid-template-columns: repeat(3, 1fr);
  margin: 0px 0px 70px 0px;

  @media (max-width: 870px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 0px 0px 60px 0px;
  }

  @media (max-width: 700px) {margin: 0px 0px 50px 0px;}
  @media (max-width: 480px) {margin: 0px 0px 34px 0px; grid-gap: 30px 16px;}
}

.release-card {
  position: relative;
  border-bottom: 1px solid $color-purple;
  h4, h5, a {color: $color-purple;}
  h4 {
    margin-top: 18px;
    margin-bottom: 4px;
  }
  h5  {
    @include Gilroy-Regular;
    margin-bottom: 14px;
  }
  a {
    position: absolute;
    font-size: 1em;
    float: right;
    right: 0;
    bottom: 0;
    &:hover {color: $color-red;}
  }
}

.release-card-profile {
  @media (max-width: 870px) {
    h4 {margin-top: 16px; margin-bottom: 2px;}
    h5  {margin-bottom: 12px;}
  }
  @media (max-width: 480px) {
    h4 {margin-top: 12px; margin-bottom: 0px; font-size: .86em;}
    h5  {margin-bottom: 8px; font-size: 0.8em;}
  }
}

.release-text {position: relative;}

.release {
  overflow: hidden;
  padding-top: 100%;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
