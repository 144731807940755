/* SLIDENAV STYLES - fullPage.js
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#fp-nav {
	z-index: 0 !important;
}



#fp-nav ul li a span,
.fp-slidesNav ul li a span {
	background: white;
	width: 12px;
	height: 12px;
	margin: -6px 0 0 -6px;

	@media (max-width: 700px) {
		width: 6px;
		height: 6px;
		margin: -2px 0 0 -2px;
	}
}

#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
	width: 16px;
	height: 16px;
	margin: -8px 0 0 -8px;
	background: transparent;
	box-sizing: border-box;
	border: 1px solid $color-red2;

	@media (max-width: 700px) {
		width: 10px;
		height: 10px;
		margin: -4px 0 0 -4px;
	}
}

#fp-nav ul li {
  margin: 12px 16px;

	@media (max-width: 700px) {
		margin: 6px -2px;
	}
}
