// Global Variables

$color-background: #f1f1f1;
$color-purple: #6327D7;
$color-darkpurple: #552ea1;
$color-red: #e82b53;
$color-red2: #f7355f;
$color-white: #FFF;
$color-black: #28124f;
$color-grey: #464646;


// Font Mixins

@mixin Gilroy-ExtraBold($colour: $color-white, $fw: normal, $ls: normal, $fs: normal) {
    font-family: 'Gilroy-ExtraBold', 'sans-serif';
    @if $colour { color: $colour; }
    @if $fw { font-weight: $fw; }
    @if $fs { font-style: $ls; }
    @if $ls { letter-spacing: $ls; }
}

@mixin Gilroy-Bold($colour: $color-white, $fw: normal, $ls: normal, $fs: normal) {
    font-family: 'Gilroy-Bold', 'sans-serif';
    @if $colour { color: $colour; }
    @if $fw { font-weight: $fw; }
    @if $fs { font-style: $ls; }
    @if $ls { letter-spacing: $ls; }
}

@mixin Gilroy-Regular($fw: normal, $ls: normal, $fs: normal) {
    font-family: 'Gilroy-Regular', 'sans-serif';
    @if $fw { font-weight: $fw; }
    @if $fs { font-style: $ls; }
    @if $ls { letter-spacing: $ls; }
}


// Global Styles

*{box-sizing: border-box;}

html, body {
  background-color: $color-purple;
  margin: 0;
  padding: 0;
  max-width: 100%;
  min-height: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
	@include Gilroy-ExtraBold;
  color: $color-white;
}

main {
  position: relative;
  margin: 0;
  min-height:100%;
  width: 100%;
  overflow-x: hidden;
}

// Fonts Sizes

h1, h2  {
  @include Gilroy-ExtraBold;
  padding: 0;
  margin: 0;
}

h3, h4, h5, a {
  @include Gilroy-Bold;
  padding: 0;
  margin: 0;
}

h1 {font-size: 3.4em;}
h2 {font-size: 2.7em;}
h3 {font-size: 1.5em;}
h4 {font-size: 1em;}
h5 {font-size: 0.875em;}

a {
  font-size: 1.375em;
  text-decoration: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  width: 132px;
  transition: all .18s ease;

  &:hover {color: $color-red !important;}
  &:focus {outline: none;}
}

p {
  @include Gilroy-Regular;
  margin: 0; font-size: 1em; line-height: 1.6em;
}

@media (max-width: 1080px) {
  h1 {font-size: 2.4em;}
  h2 {font-size: 1.95em;}
  h3 {font-size: 1.2em;}
  a {font-size: 1.2em;}
}

@media (max-width: 700px) {
  h1 {font-size: 1.48em;}
  h3 {font-size: 1em;}
  a {font-size: 1.1em;}
  p {font-size: .85em;}
}

@media (max-width: 480px) {
  h2 {font-size: 1.4em;}
  h3 {font-size: .92em; line-height: 1.4em;}
}

.link {
  position: absolute;
  width: auto;
  height: auto;
  text-align: left;
  bottom: 110px;

  @media (max-width: 1400px) {bottom: 90px;}
  @media (max-width: 1080px) {bottom: 74px;}
  @media (max-width: 870px) {bottom: 64px;}
  @media (max-width: 640px) {bottom: 36px;}

  &:after {
    position: absolute;
    content: "";
    left: 0;
    width: 50px;
    top: 38px;
    height: 2px;
    z-index: 50px;
    background-color: $color-red;
    transition: all .3s ease;

    @media (max-width: 1080px) {top: 32px;}
  }

  &:hover {&:after {width: 100%;}}
}

#title-purple {
  color: $color-darkpurple;
  &:after {background-color: $color-darkpurple;}
}

#link-red {
  &:hover {color: $color-darkpurple;}
  &:after {background-color: $color-darkpurple;}
}

.text-purple {color: $color-purple;}

.background-purple {
  background-color: $color-purple;
}

.background-white {
  background-color: $color-background;
}

.background-black {
  background-color: $color-black;
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.container {
  display: flex;
  position: relative;
  justify-content: center;
}
