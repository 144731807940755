.fail-sub, .fail-title {
  position: absolute;
  color: $color-purple;
  top: 50%;
  text-align: center;
  padding: 0 60px 0 60px;
}

.fail-title {
  margin-top: -260px;
  font-size: 2.7em;
  @media (max-width: 700px) {margin-top: -190px; font-size: 1.95em;}
  @media (max-width: 480px) {margin-top: -160px; font-size: 1.4em;}
}


.fail-sub {
  margin-top: 140px;
  @media (max-width: 700px) {margin-top: 80px;}
  @media (max-width: 480px) {margin-top: 60px;}
}

.centre-404 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-404 {background-color: none;}

.number-404 {
  color: $color-purple;
  font-size: 13.8em;
  @media (max-width: 700px) {font-size: 8.0em; margin-top: -20px;}
}

.number-left {
  margin-right: 100px;
  @media (max-width: 700px) {margin-right: 60px;}
}

.number-right {
  margin-left: 100px;
  @media (max-width: 700px) {margin-left: 60px;}
}

#outer-ring-fail {
  fill: $color-purple;
  animation: outer-circle 3s linear infinite;
  transform-origin: 50% 50%;
}

@keyframes outer-circle-fail {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

#middle-ring-fail {
  fill: $color-purple;
  animation: middle-circle 3s linear infinite;
  transform-origin: 50% 50%;
}

@keyframes middle-circle-fail {
  from {transform: rotate(360deg);}
  to {transform: rotate(0deg);}
}

#centre-dot-fail {fill: $color-purple;}

.footer-404 {bottom: 0;}
