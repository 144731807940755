@import "components/header-image";

.article-container {
  position: relative;
  display: flex;
  background-color: $color-background;
  justify-content: center;
  padding: 80px 96px 80px 96px;
  overflow: hidden;

  @media (max-width: 1200px) {padding: 70px 80px 80px 126px;}
  @media (max-width: 700px) {padding: 40px 80px 40px 126px;}
  @media (max-width: 480px) {padding: 72px 30px 30px 30px;}
}

.article-container-profile {
  @media (max-width: 480px) {padding: 30px 30px 30px 30px;}
}

.article-content {
  position: relative;
  max-width: 1000px;
  overflow: hidden;
  color: $color-darkpurple;

  h3 {margin-bottom: 20px; color: $color-purple;}

  p {margin-bottom: 30px;}

  @media (max-width: 480px) {
    h3 {margin-bottom: 14px; font-size: 1.1em;}
    p {margin-bottom: 20px;}
  }
}

.article-image-fw {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin: 20px 0 50px 0;
  overflow: hidden;

  @media (max-width: 700px) {margin: 4px 0 30px 0;}
}

.article-image-Col2 {
  display: flex;
  justify-content: space-between;

  @media (max-width: 700px) {flex-direction: column;}
}

.article-image-hw {
  width: 49%;
  height: 49%;
  object-fit: cover;
  margin: 20px 0 50px 0;
  overflow: hidden;
  @media (max-width: 700px) {width: 100%; height: 100%; margin: 4px 0 30px 0;}
}

.article-details {
  position: absolute;
  top: 176px;
  left: -42px;
  transform: rotate(-90deg);

  h5 {
    color: $color-red;
    @include Gilroy-Regular;
    letter-spacing: .2em;

    &:after {
      position: absolute;
      content: "";
      left: 0;
      width: 40px;
      top: 28px;
      height: 1px;
      background-color: $color-red;
    }
  }

  @media (max-width: 1200px) {
    left: -46px; top: 152px;
    h5 {
      font-size: 0.75em;
      &:after {top: 24px; width: 32px;}
    }
  }
  @media (max-width: 700px) {top: 120px;}
  @media (max-width: 480px) {display: none;}
}

.share-links {
  h4 {
    color: $color-purple;
    position: absolute;
    top: 350px;
    left: 50px;
    @media (max-width: 1200px) {top: 300px; left: 34px;}
    @media (max-width: 700px) {top: 258px; font-size: .9em;}
    @media (max-width: 480px) {
      top: 28px;
      left: 30px;
      font-size: 1.05em;
    }
  }

}

.share-icons {
  position: absolute;

  top: 386px;
  left: 50px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  li {
    margin-bottom: 16px;
    width: 40px;
    height: 40px;
    border: 1px solid $color-purple;
    border-radius: 50%;

    a {
      color: $color-purple;
      font-size: 1.15em;
      padding: 0.55em;
      width: auto;
    }

    &:hover {
      background-color: $color-purple;
      a {color: $color-white !important;}
    }
  }

  @media (max-width: 1200px) {
    top: 336px; left: 34px;
    li {
      width: 36px; height: 36px;
      a {font-size: .94em; padding: 0.6em;}
    }
  }

  @media (max-width: 700px) {top: 290px;}
  @media (max-width: 480px) {
    top: 22px; left: 90px; flex-direction: row;
    li {
      width: 32px; height: 32px; margin-right: 8px;
      a {font-size: .9em; padding: 0.56em; }
    }
  }
}

.fa-facebook-f {
  margin-left: .16em;
}

.video-container {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
  margin-top: 40px;
  overflow: hidden;

  @media (max-width: 700px) {margin-top: 30px; margin-bottom: 30px;}
}

.video {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}
