/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/398b00");


@font-face {font-family: 'Gilroy-Regular';
  src: url('../fonts/398B00_0_0.eot');
  src: url('../fonts/398B00_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/398B00_0_0.woff2') format('woff2'),url('../fonts/398B00_0_0.woff') format('woff'),url('../fonts/398B00_0_0.ttf') format('truetype'),url('../fonts/398B00_0_0.svg#wf') format('svg');}


@font-face {font-family: 'Gilroy-ExtraBold';
  src: url('../fonts/398B00_1_0.eot');
  src: url('../fonts/398B00_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/398B00_1_0.woff2') format('woff2'),url('../fonts/398B00_1_0.woff') format('woff'),url('../fonts/398B00_1_0.ttf') format('truetype'),url('../fonts/398B00_1_0.svg#wf') format('svg');}


@font-face {font-family: 'Gilroy-Bold';
  src: url('../fonts/398B00_2_0.eot');
  src: url('../fonts/398B00_2_0.eot?#iefix') format('embedded-opentype'),url('../fonts/398B00_2_0.woff2') format('woff2'),url('../fonts/398B00_2_0.woff') format('woff'),url('../fonts/398B00_2_0.ttf') format('truetype'),url('../fonts/398B00_2_0.svg#wf') format('svg');}
