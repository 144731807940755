html,
body,
ul,
ol {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}
