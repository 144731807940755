$button-height: 18px;
$button-width: 32px;

.button_container {
  @media (min-width: 700px) {display: none;}
  position: fixed;
  top: 32px;
  right: 26px;
  height: $button-height;
  width: $button-width;
  cursor: pointer;
  z-index: 100;
  transition: opacity .25s ease;

  .top {width: 50%;}
  .bottom {right: 0; width: 50%;}

  &:hover {opacity: .7;}

  &.active {
    .top {
      transform: translateY(8px) translateX(0) rotate(45deg);
      background: $color-white !important;
      width: 100%;
    }
    .middle {
      opacity: 0;
      background: $color-white !important;
    }

    .bottom {
      transform: translateY(-8px) translateX(0) rotate(-45deg);
      background: $color-white !important;
      width: 100%;
    }
  }

  span {
  background: $color-white;
  border: none;
  height: 2px;
  width: 100%;
  position: absolute;
  top: 0;
  transition:  all .35s ease;
  cursor: pointer;

    &:nth-of-type(2) {
      top: 8px;
    }

    &:nth-of-type(3) {
      top: 16px;
    }
  }
}

.span-purple {
  background: $color-purple !important;
}


.open {
  .button_container {
    span {background: $color-white;}
  }
}

.overlay {
  position: fixed;
  background: $color-purple;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, height .35s;
  overflow: hidden;

  &.open {
    opacity: .97;
    visibility: visible;
    height: 100%;

    li {
      animation: fadeInRight .5s ease forwards;
      animation-delay: .35s;

      &:nth-of-type(2) {
        animation-delay: .4s;
      }
      &:nth-of-type(3) {
        animation-delay: .45s;
      }
      &:nth-of-type(4) {
        animation-delay: .50s;
      }
      &:nth-of-type(5) {
        animation-delay: .55s;
      }
      &:nth-of-type(6) {
        animation-delay: .60s;
      }
    }
  }
  nav {
    position: relative;
    height: 35%;
    top: 45%;
    transform: translateY(-50%);
    font-size: 28px;

    text-align: left;
    left: 30px;
  }
  ul {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    height: 100%;

    li {
      display: block;
      height: 25%;
      height: calc(100% / 4);
      min-height: 50px;
      position: relative;
      opacity: 0;

      a {
        display: block;
        position: relative;
        text-decoration: none;
        //overflow: hidden;
      }
    }
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
