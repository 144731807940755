// Preloader

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-purple;
  z-index: 99;
}

#status {
  width: 160px;
  height: 160px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -100px 0 0 -80px;

  @media (max-width: 700px) {
    width: 100px;
    height: 100px;
    margin: -70px 0 0 -50px;
  }
}

// Preloader Graphics

#outer-ring {
  fill: $color-white;
  animation: outer-circle 3s linear infinite;
  transform-origin: 50% 50%;
}

@keyframes outer-circle {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

#middle-ring {
  fill: $color-white;
  animation: middle-circle 3s linear infinite;
  transform-origin: 50% 50%;
}

@keyframes middle-circle {
  from {transform: rotate(360deg);}
  to {transform: rotate(0deg);}
}

#centre-dot {fill: $color-white;}
