.scroll-arrow {
  position: absolute;
  float: right;
  //display: flex;
  //justify-content: flex-end;
  bottom: 0;
  right: 50px;
  height: 96px;
  z-index: 1;
  animation-fill-mode: forwards;
  animation-name: arrow;
  animation: arrow 1s infinite ease-in-out;

  @media (max-width: 1080px) {right: 40px; height: 76px;}
  @media (max-width: 680px) {right: 14px; height: 60px;}

  @keyframes arrow {
    0% {bottom: 14px;}
    50% {bottom: 10px;}
    100% {bottom: 14px;}
  }

  img {
    height: 34px;

    @media (max-width: 680px) {height: 26px;}
  }
}
