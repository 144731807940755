@import "components/profile-videos";

.share-links-profile {
  h4 {
    top: auto;
    @media (max-width: 480px) {top: 28px;}
  }
  @media (max-width: 480px) {display: none;}
}

.share-icons-profile {
  top: 114px;
  @media (max-width: 1200px) {top: 102px;}
  @media (max-width: 700px) {top: 68px;}
  @media (max-width: 480px) {top: 22px;}
}

.profile-image-fw {
  margin: 20px 0 70px 0;
  @media (max-width: 870px) {margin: 4px 0 54px 0;}
  @media (max-width: 700px) {margin: 4px 0 30px 0;}
}
