.NavigationButton {
 color: inherit;
}

.menu_white {
 color: green;
}

.menu_black {
 color: red;
}
