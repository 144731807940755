.header-section {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 75vh;
  padding: 0 96px 0 96px;
  align-items: flex-end;
  z-index: 0;

  &:after {
    content: '';
    display: block;
    position: absolute;
    background-image: linear-gradient(to bottom, transparent 30%, #32166a 100%);
    margin-top: 1px;
    height: 40%;
    width: 100%;

    img {
      margin-left: 20px;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 80px 0 126px;
    &:after {left: -20px; width: 105%;}

  }
  @media (max-width: 870px) {height: 65vh;}
  @media (max-width: 700px) {height: 70vh;}
  @media (max-width: 480px) {padding: 0 30px 0 30px;}
}

.header-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  object-fit: cover;
  overflow-x: hidden;
}

.header-image-left {
  @media (max-width: 870px) {
    width: auto;
    left: -90px;
    height: 100%;
  }
}

.header-text {
  position: relative;
  margin: 0;
  z-index: +1;
  max-width: 1000px;
  width: 1000px;

  h1  {
    margin-bottom: 30px;
    @media (max-width: 1200px) {margin-bottom: 26px;}
    @media (max-width: 700px) {margin-bottom: 16px;}
  }

  h3 {
    margin-bottom: 120px;
    @media (max-width: 1200px) {margin-bottom: 100px;}
    @media (max-width: 870px) {margin-bottom: 50px;}
  }
}


.header-text-profile {
  h1  {
    margin-bottom: 16px;
    @media (max-width: 1200px) {margin-bottom: 16px;}
    @media (max-width: 700px) {margin-bottom: 14px;}
    @media (max-width: 480px) {margin-bottom: 12px;}
  }

  h3 {
    margin-bottom: 40px;
    @media (max-width: 1200px) {margin-bottom: 36px;}
    @media (max-width: 870px) {margin-bottom: 26px;}
  }

  ul {display: flex;}

  li {
    margin-bottom: 90px;
    margin-right: 12px;
    width: 40px;
    height: 40px;
    border: 1px solid $color-white;
    border-radius: 50%;

    a {font-size: 1.15em; padding: 0.55em; width: auto;}

    &:hover {
      background-color: $color-white;
      a {color: $color-purple !important;}
    }

    @media (max-width: 700px) {
      margin-bottom: 70px; width: 32px; height: 32px; margin-right: 8px;
      a {font-size: .9em; padding: 0.56em; }
    }
    @media (max-width: 480px) {margin-bottom: 36px;}
  }
}

.fa-youtube {margin-left: -0.09em;}
.fa-soundcloud {margin-left: -0.15em; margin-top: -0.06em;}
.fa-instagram {
  margin-left: -0.07em; margin-top: -0.06em; font-size: 1.3em !important;
  @media (max-width: 870px) {margin-left: -0.09em;}
  @media (max-width: 700px) {font-size: 1.12em !important; margin-left: -0.17em; margin-top: -0.19em;}
}
