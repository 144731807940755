.headline-animation {
  animation: fadeInTop .5s ease forwards;
  animation-delay: .35s;
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
