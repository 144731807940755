@import "components/preloader";
@import "components/scrollArrow";

.homepage-content {
  margin: 0;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;

  &:after {
    content: '';
    display: block;
    position: absolute;
    background-image: linear-gradient(to bottom, transparent 40%, #32166a 100%);
    margin-top: 1px;
    bottom: 0;
    height: 50%;
    width: 100%;
  }
}

.homepage-content2 {
  margin: 0;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.fullpage-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  object-fit: cover;
}

.fullpage-release {
  @media (max-width: 870px) {padding-top: 60px; height: 90%;}
  @media (max-width: 700px) {height: 82%;}
}

.homepageText {
  position: absolute;
  margin: 0;
  bottom: 100px;
  left: 60px;
  display: flex;
  z-index: 1;
  flex-direction: column;
  max-width: 700px;

  opacity: 0;
  transform: translateY(50px);
  transition: all 1s ease-out;

  @media (max-width: 1080px) {bottom: 70px; left: 54px;}
  @media (max-width: 870px) {max-width: 500px; bottom: 54px; left: 46px;}
  @media (max-width: 700px) {max-width: 82%; bottom: 50px; left: 20px;}

  h1  {
    margin-bottom: 14px;
    text-align: left;
    font-size: 2.8em;
    @media (max-width: 1080px) {font-size: 2.4em; margin-bottom: 10px;}
    @media (max-width: 700px) {font-size: 1.48em; margin-bottom: 6px;}
  }

  a {
    position: relative;
    display: block;
    width: max-content;
    opacity: 0;
    transform: translateY(50px);
    transition: all .3s ease-out;

    &:after {
      position: absolute;
      content: "";
      left: 0;
      width: 50px;
      top: 38px;
      height: 2px;
      background-color: $color-red;
      transition: all .2s ease;

      @media (max-width: 1080px) {top: 32px;}
    }

    &:hover {&:after {width: 100%;}}
  }
}

.fade-in {
  .homepageText {
    animation: fadeInBottom .5s ease forwards;
    animation-delay: .2s;
    text-align: left;
    a {
      animation: fadeInBottom .5s ease forwards;
      animation-delay: .3s;
    }
  }
  @keyframes fadeInBottom {100% {opacity: 1; transform: translateY(0);}}
}

.news-title {
  margin-bottom: 30px;
  color: $color-red;
  text-transform: uppercase;
  font-size: 1.2em !important;
  letter-spacing: .4em;
  @media (max-width: 1080px) {margin-bottom: 12px;}
  @media (max-width: 700px) {font-size: 1em !important;}
  @media (max-width: 480px) {font-size: .92em !important;}
}

.news-subhead {
  margin-bottom: 44px;
  @include Gilroy-Regular;
  font-size: 1.2em !important;

  @media (max-width: 1080px) {margin-bottom: 40px;}
  @media (max-width: 700px) {margin-bottom: 26px; font-size: .92em !important;}
}
